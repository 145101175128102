exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-support-us-jsx": () => import("./../../../src/pages/support-us.jsx" /* webpackChunkName: "component---src-pages-support-us-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-art-3-resources-to-learn-to-draw-for-free-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/art/3-resources-to-learn-to-draw-for-free/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-art-3-resources-to-learn-to-draw-for-free-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-business-3-resources-to-learn-business-for-free-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/business/3-resources-to-learn-business-for-free/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-business-3-resources-to-learn-business-for-free-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-creativity-3-steps-to-improve-creativity-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/creativity/3-steps-to-improve-creativity/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-creativity-3-steps-to-improve-creativity-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-health-3-resources-health-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/health/3-resources-health/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-health-3-resources-health-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-history-beginner-history-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/history/beginner-history/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-history-beginner-history-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-markdown-guide-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/markdown-guide/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-markdown-guide-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-philosophy-3-beginner-philosophy-resources-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/philosophy/3-beginner-philosophy-resources/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-philosophy-3-beginner-philosophy-resources-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-psychology-4-beginner-psychology-resources-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/psychology/4-beginner-psychology-resources/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-psychology-4-beginner-psychology-resources-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-science-3-biology-resources-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/science/3-biology-resources/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-science-3-biology-resources-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-science-3-chemistry-resources-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/science/3-chemistry-resources/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-science-3-chemistry-resources-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-technology-4-things-every-programmer-should-know-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/technology/4-things-every-programmer-should-know/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-technology-4-things-every-programmer-should-know-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-technology-python-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/technology/python/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-technology-python-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-template-article-template-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/jcampbell/projects/websites/insightdc/revista-gatsby-blog-magazine/content/template/article-template/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-users-jcampbell-projects-websites-insightdc-revista-gatsby-blog-magazine-content-template-article-template-post-mdx" */)
}

